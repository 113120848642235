/* eslint-disable max-len */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

function Footer() {
  return (
    <div className="text-black-footer mr-2 text-right font-gayathri" style={{ fontSize: '12px' }}>
      <footer>© 2024 Aditi Acharya. All rights reserved.</footer>
    </div>
  );
}

export default Footer;
